import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import awsExports from "./aws-exports";
import getUserGroups from "./userInfo";
import { Amplify } from "aws-amplify";
Amplify.configure(awsExports);
getUserGroups();

ReactDOM.render(
  <>
    <BrowserRouter>
      {/* <React.StrictMode> */}
      <App />
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </>,
  document.getElementById("root")
);
