import styled from "styled-components";

import React from "react";

import CloseIcon from "@material-ui/icons/Close";
import { HamburgerStore } from "./Store";
import { Footer } from "./Footer";

import { useAuthenticator } from "@aws-amplify/ui-react";
import LogoutIcon from "@mui/icons-material/Logout";

export const NavLinks = ({ topBarLinks }) => {
  const { setHamburger } = HamburgerStore();
  const { user, signOut } = useAuthenticator(context => [context.user]);

  const showUser = () => {
    if (user) {
      return (
        <LinkContainer href="" target="_blank">
          <StyledLogoutIcon onClick={signOut} />
          <Link>{user.username}</Link>
        </LinkContainer>
      );
    }
  };
  return (
    <Container>
      <TitleContainer>
        <Title>קישורים</Title>
        <StyledCloseIcon onClick={() => setHamburger(false)} />
      </TitleContainer>
      <Links>
        {topBarLinks.map((item, id) => {
          return (
            <LinkContainer href={item.href} target="_blank">
              {item.icon}
              <Link>{item.title}</Link>
            </LinkContainer>
          );
        })}
        {showUser()}
      </Links>
      <Footer />
    </Container>
  );
};

const StyledLogoutIcon = styled(LogoutIcon)`
  width: 16px;
  height: 16px;
  margin: 0px 0px 0px 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

const LinkContainer = styled.a`
  display: flex;
  text-decoration: none;
  color: #000;
  margin-right: 24px;
`;

const Link = styled.div`
  margin-bottom: 24px;
  width: 110px;
  height: 24px;
  margin: 0px 0px 24px 45px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
  color: #000;
`;

const StyledCloseIcon = styled(CloseIcon)`
cursor: pointer;
width: 24px;
height: 24px;
margin-left: 16px;

}`;

const Title = styled.div`
height: 24px;
font-family: Rubik;
font-size: 20px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: normal;
text-align: right;
color: #000;
margin-right: 24px;
width: 100%;

}`;

const TitleContainer = styled.div`
  background-color: #e7f6ff;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
