import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { AddDateTitle } from "../App";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import { ViewerInfo } from "./ViewerInfo";
import { ViewStore, ItemsStore, ScrollStore } from "./Store";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useSearchParams } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

import archieExports from "../archie-exports";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export function Viewer({ isPhotoGallery = false }) {
  const { view, setView } = ViewStore();
  const { items } = ItemsStore();
  const [numPages, setNumPages] = useState(null);
  const [showAllPages, setShowAllPages] = useState(false);

  const [showInfoView, setShowInfoView] = useState(false);
  const [editInfo, setEditInfo] = useState(false);

  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);

  const [loadingImg, setLoadingImg] = useState(true);
  const [progress, setProgress] = useState(1);

  const [zoom, setZoom] = useState(false);
  const [params, setSearchParams] = useSearchParams();

  const { setScrollPosition } = ScrollStore();
  const { user } = useAuthenticator(context => [context.user]);

  const updateLoadingImg = () => {
    setLoadingImg(false);
  };
  const [scale, setScale] = useState(1);
  useEffect(() => {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    function handleResize() {
      if (window.innerWidth > 500) {
        setShowInfoView(true);
      }
    }

    if (window.innerWidth > 500) {
      setShowInfoView(true);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setLoadingImg(false);
    setNumPages(numPages);
    setShowAllPages(true);
  }
  function onDocumentLoading({ loaded, total }) {
    setProgress(Math.floor((loaded / total) * 100));
  }

  const handleCloseClick = () => {
    if (!isPhotoGallery) {
    }
    setView(null);
    setSearchParams(params);
    setScrollPosition(true);
  };

  const handleInfoClick = () => {
    setShowInfoView(true);
  };

  const handleEditInfoClick = () => {
    setShowInfoView(true);
    setEditInfo(true);
  };

  const showAll = scale => {
    const pages = [];
    if (numPages > 0) {
      for (let i = 1; i <= numPages; i++) {
        pages.push(<StyledPage pageNumber={i} scale={scale} />);
      }

      return pages;
    }
  };

  const getAssetUrl = view => {
    let S3OriginalsUrl = "";
    switch (view.dcAccessRights) {
      case "public":
        S3OriginalsUrl =
          archieExports["public_assets_bucket"] +
          "/originals/" +
          view.id +
          "." +
          view.dcFormat;

        break;
      case "private":
        S3OriginalsUrl =
          archieExports["private_assets_bucket"] +
          "/originals/" +
          view.id +
          "." +
          view.dcFormat;

        break;

      default:
        S3OriginalsUrl =
          "https://archie-beeri-" +
          view.dcAccessRights +
          ".s3-eu-west-1.amazonaws.com/originals/" +
          view.id +
          "." +
          view.dcFormat;
    }
    return S3OriginalsUrl;
  };
  const handleClick = goBack => {
    setLoadingImg(true);
    let current = items.findIndex(item => item.id === view.id);
    if (goBack) {
      if (current === items.length - 1) {
        setView(items[0]);
      } else {
        setView(items[current + 1]);
      }
    } else {
      if (current === 0) {
        setView(items[items.length - 1]);
      } else {
        setView(items[current - 1]);
      }
    }
  };
  const mainAsset = (view, startX, setStartX, endX, setEndX) => {
    const S3OriginalsUrl = getAssetUrl(view);

    const swipeHandler = () => {
      if (startX - endX > 150) {
        handleClick(true);
      }

      if (startX - endX < -150) {
        handleClick(false);
      }
    };
    switch (view.dcFormat) {
      case "mp3":
        return (
          <ImgContainer>
            {items.length > 1 ? (
              <ArrowCircleRight onClick={() => handleClick(false)}>
                <ArrowRightIconWrapper />
              </ArrowCircleRight>
            ) : null}
            <ImgWrapper>
              <Audio
                progressJumpSteps={{ backward: 30000, forward: 30000 }}
                autoPlay
                showJumpControls={true}
                onTouchStart={e => {
                  setStartX(e.changedTouches[0].screenX);
                }}
                onTouchEnd={e => {
                  swipeHandler();
                }}
                onTouchMove={e => {
                  setEndX(e.targetTouches[0].clientX);
                }}
                src={S3OriginalsUrl}
              ></Audio>
            </ImgWrapper>
            {items.length > 1 ? (
              <ArrowCircleLeft onClick={() => handleClick(true)}>
                <ArrowLeftIconWrapper />
              </ArrowCircleLeft>
            ) : null}
          </ImgContainer>
        );
      case "mp4":
      case "m4v":
      case "mov":
        return (
          <ImgContainer>
            {items.length > 1 ? (
              <ArrowCircleRight onClick={() => handleClick(false)}>
                <ArrowRightIconWrapper />
              </ArrowCircleRight>
            ) : null}
            <ImgWrapper>
              <Video
                autoPlay
                onTouchStart={e => {
                  setStartX(e.changedTouches[0].screenX);
                }}
                onTouchEnd={e => {
                  swipeHandler();
                }}
                onTouchMove={e => {
                  setEndX(e.targetTouches[0].clientX);
                }}
                preload="metadata"
                controls
                src={S3OriginalsUrl}
              ></Video>
            </ImgWrapper>
            {items.length > 1 ? (
              <ArrowCircleLeft onClick={() => handleClick(true)}>
                <ArrowLeftIconWrapper />
              </ArrowCircleLeft>
            ) : null}
          </ImgContainer>
        );
      case "png":
      case "jpg":
      case "jpeg":
        return (
          <ImgContainer>
            {items.length > 1 ? (
              <ArrowCircleRight onClick={() => handleClick(false)}>
                <ArrowRightIconWrapper />
              </ArrowCircleRight>
            ) : null}
            <ImgWrapper>
              <CircularProgress
                style={{ display: loadingImg ? "block" : "none" }}
              />

              <Img
                zoom={zoom}
                onClick={() => setZoom(!zoom)}
                style={{ display: loadingImg ? "none" : "block" }}
                onTouchStart={e => {
                  setStartX(e.changedTouches[0].screenX);
                }}
                onTouchEnd={e => {
                  swipeHandler();
                }}
                onTouchMove={e => {
                  setEndX(e.targetTouches[0].clientX);
                }}
                src={S3OriginalsUrl}
                onLoad={updateLoadingImg}
              />
            </ImgWrapper>
            {items.length > 1 ? (
              <ArrowCircleLeft onClick={() => handleClick(true)}>
                <ArrowLeftIconWrapper />
              </ArrowCircleLeft>
            ) : null}
          </ImgContainer>
        );
      case "pdf":
        return (
          <PdfWrapper>
            <ZoomControl style={{ display: loadingImg ? "none" : "flex" }}>
              <StyledZoomOut
                onClick={() =>
                  setScale(prev => {
                    return prev >= 0.5 ? prev - 0.25 : prev;
                  })
                }
              />
              <StyledZoom onClick={() => setScale(1)} />

              <StyledZoomIn
                onClick={() =>
                  setScale(prev => {
                    return prev < 2 ? prev + 0.25 : prev;
                  })
                }
              />
            </ZoomControl>

            <ImgContainer>
              {items.length > 1 ? (
                <ArrowCircleRight onClick={() => handleClick(false)}>
                  <ArrowRightIconWrapper />
                </ArrowCircleRight>
              ) : null}
              <Stack
                style={{
                  display: loadingImg ? "flex" : "none",
                  width: "100%"
                }}
                alignItems="center"
              >
                <CircularProgress
                  variant="determinate"
                  value={progress}
                  style={{ display: loadingImg ? "block" : "none" }}
                />
              </Stack>
              <ImgWrapper style={{ display: loadingImg ? "none" : "block" }}>
                <StyledDocument
                  onTouchStart={e => {
                    setStartX(e.changedTouches[0].screenX);
                  }}
                  onTouchEnd={e => {
                    swipeHandler();
                  }}
                  onTouchMove={e => {
                    setEndX(e.targetTouches[0].clientX);
                  }}
                  file={S3OriginalsUrl}
                  onLoadProgress={onDocumentLoading}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {showAllPages ? showAll(scale) : null}
                </StyledDocument>
              </ImgWrapper>
              {items.length > 1 ? (
                <ArrowCircleLeft onClick={() => handleClick(true)}>
                  <ArrowLeftIconWrapper />
                </ArrowCircleLeft>
              ) : null}
            </ImgContainer>
          </PdfWrapper>
        );

      default:
        return null;
    }
  };

  const link =
    "mailto:archion.beeri+comment@gmail.com?subject=" +
    view.dcTitle +
    " מזהה: " +
    view.id;
  const S3OriginalsUrl = getAssetUrl(view);

  return (
    <GeneralGrid showInfoView={showInfoView}>
      <ViewerInfo
        data={view}
        setShowInfoView={setShowInfoView}
        showInfoView={showInfoView}
        editInfo={editInfo}
        setEditInfo={setEditInfo}
        setData={setView}
        handleCloseClick={handleCloseClick}
      />
      <Container>
        <Header>
          <Icons>
            {showInfoView ? null : (
              <StyledInfoOutlinedIcon onClick={handleInfoClick} />
            )}

            <StyledGetAppIconLink
              href={S3OriginalsUrl}
              download={view.id + "." + view.dcFormat}
              target={"_blank"}
            >
              <StyledGetAppIcon />
            </StyledGetAppIconLink>
            <StyledCommentLink user={user && !editInfo} href={link}>
              <StyledComment />
            </StyledCommentLink>
            {user && !editInfo ? (
              <StyledEditInfoLink onClick={handleEditInfoClick}>
                <StyledEditIcon />
              </StyledEditInfoLink>
            ) : null}
            <StyledArrowBackOutlinedIcon onClick={handleCloseClick} />
          </Icons>
          <ItemDetails>
            <Title>{view.dcTitle}</Title>
            <AddDateTitle dateTime={view.dcDate} />
          </ItemDetails>
        </Header>
        {mainAsset(view, startX, setStartX, endX, setEndX)}
      </Container>
    </GeneralGrid>
  );
}

const StyledArrowBackOutlinedIcon = styled(ArrowBackOutlinedIcon)`
  cursor: pointer;

  width: 24px;
  height: 24px;
  margin-left: 16px;
`;

const StyledGetAppIcon = styled(GetAppIcon)`
  width: 24px;
  height: 24px;
`;

const StyledComment = styled(CommentOutlinedIcon)`
  width: 24px;
  height: 24px;
`;

const StyledEditIcon = styled(EditIcon)`
  width: 24px;
  height: 24px;
`;

const StyledCommentLink = styled.a`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: ${props => (props.user ? "4px" : "auto")};
  margin-right: 16px;
  color: unset;
`;

const StyledEditInfoLink = styled.a`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: 16px;
  color: unset;
`;

const StyledGetAppIconLink = styled.a`
  margin-right: 16px;
  color: unset;
`;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  cursor: pointer;
`;

const GeneralGrid = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-columns: ${props => (props.showInfoView ? "1fr" : "0fr 1fr")};

  @media (min-width: 500px) {
    grid-template-columns: ${props =>
    props.showInfoView ? "30% 70%" : "0fr 1fr"};
  }
`;

const Container = styled.div`
  width: 100%;
  background-color: black;
  z-index: 200;
  overflow-y: overlay;
  margin-bottom: 0;
  overflow-x: hidden;
  height: 100vh;
`;

const Header = styled.div`
  width: 100%;
`;

const Icons = styled.div`
  display: flex;
  height: 24px;
  margin-top: 16px;
  color: white;
  width: 100%;
  @media (min-width: 800px) {
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;

const ItemDetails = styled.div`
  @media (min-width: 800px) {
    display: none;
  }
`;
const Title = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 24px 0px 24px;
  font-family: Rubik;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
  direction: rtl;


}
`;

const PdfWrapper = styled.div`
height:100%;
`;

const ZoomControl = styled.div`
  width: 112px;
  height: 39px;
  margin-right: calc(50% - 56px);
  top: 90%;
  border-radius: 4px;
  background-color: #d8d8d8;
  display: flex;
  align-items: center;
  z-index: 100;
  position: sticky;
`;

const StyledZoomIn = styled(AddIcon)`
  width: 24px;
  height: 24px;
  margin: 0 0 0 8px;
  padding: 5px;
  cursor: pointer;
`;
const StyledZoomOut = styled(RemoveIcon)`
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  padding: 11px 5px;
  cursor: pointer;
`;

const StyledZoom = styled(ZoomOutIcon)`
  width: 17px;
  height: 17px;
  cursor: pointer;
`;

const StyledDocument = styled(Document)`
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledPage = styled(Page)`
  position: fixed;
  margin-bottom: 10px;
  overflow: hidden;
  .react-pdf__Page__canvas {
    margin: 0 auto;
    height: auto !important;
    max-width: 100% !important;
  }
`;

const Img = styled.img`
  width: 100%;
  object-fit: ${props => (props.zoom ? "cover" : "contain")};
  height: auto;
  max-height: ${props => (props.zoom ? "none" : "100%")};

  display: flex;
  align-self: start;
  overflow: ${props => (props.zoom ? "scroll" : "hidden")};
  cursor: ${props => (props.zoom ? "zoom-out" : "zoom-in")};

  @media (min-width: 500px) {
    width: calc(100% - 120px);
  }
`;

const Audio = styled(AudioPlayer)`
  width: 100%;
  top: 50vh;
  direction: ltr;

  @media (min-width: 500px) {
    height: 150px;
    width: 700px;
  }
`;

const Video = styled.video`
  width: 100%;
  object-fit: contain;
  height: auto;
  max-height: 100%;
  display: flex;
  align-self: start;

  @media (min-width: 500px) {
    width: calc(100% - 120px);
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  @media (min-width: 500px) {
    height: calc(100% - 100px);
  }
`;

const ImgWrapper = styled.div`
  overflow: auto;
  display: flex;
  align-items: center;
  align-self: flex-start;
  width: 100%;
  justify-content: center;
  @media (min-width: 500px) {
    height: 100%;
  }
`;

const ArrowCircleRight = styled.div`
  position: absolute;
  top: 50vh;

  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 10px;
  mix-blend-mode: difference;
  z-index: 200;
`;

const ArrowCircleLeft = styled.div`
  position: absolute;
  top: 50vh;
  left: 0px;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 10px;
  mix-blend-mode: difference;
  z-index: 200;
`;

const ArrowRightIconWrapper = styled(ArrowRightIcon)`
  color: white;
  cursor: pointer;
`;

const ArrowLeftIconWrapper = styled(ArrowLeftIcon)`
  color: white;
  cursor: pointer;
`;
