import { Auth } from "aws-amplify";

const getUserGroups = async () => {
  try {
    const userInfo = await Auth.currentAuthenticatedUser({ bypassCache: true });
    //console.log("User info: ", userInfo)
    if (userInfo) {
      console.log(
        "Authenticated user belongs to the following groups: " +
          userInfo.signInUserSession.idToken.payload["cognito:groups"]
      );
      // returns a Array<strings>
      return userInfo.signInUserSession.idToken.payload["cognito:groups"];
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

export default getUserGroups;
