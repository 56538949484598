import styled from "styled-components";

import React, { useState } from "react";
import { API } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
export const EditForm = ({ data, setEditInfo, setData, handleCloseClick }) => {
  const { user } = useAuthenticator(context => [context.user]);
  const [loading, setLoading] = useState(false);
  const isAdmin = user.getSignInUserSession().getIdToken().payload['cognito:groups'][0] === 'archie_managers';
  const deleteDoc = e => {
    e.preventDefault();

    let doc = {
      id: data.id,
      dcAccessRights: data.dcAccessRights,
      dcFormat: data.dcFormat
    };
    let confirmDelete = window.confirm("בטוח למחוק?");
    if (confirmDelete) {
      setLoading(true);

      API.del("archie_api", "/docs", { body: [doc] }).then(resp => {
        if (resp.statusCode === 200) {

          setTimeout(() => {
            setLoading(false);
            handleCloseClick();
          }, 5000);

        }
      });
    }
  };

  const updateDoc = e => {
    e.preventDefault();
    let doc =
      isAdmin
        ? {
          id: data.id,
          dcTitle: e.target.dcTitle.value,
          dcDescription: e.target.dcDescription.value.length > 0 ? e.target.dcDescription.value : null,
          dcDate: e.target.dcDate.value.length > 0 ? new Date(e.target.dcDate.value).toISOString() : null,
          dcCreator: e.target.dcCreator.value.length > 0 ? e.target.dcCreator.value
            .split(",")
            .map(item => item.trim()) : null,
          dcSubject: e.target.dcSubject.value.length > 0 ? e.target.dcSubject.value
            .split(",")
            .map(item => item.trim()) : null,
          storageLocation: e.target.storageLocation.value,
          dcType: e.target.dcType.value,
          dcAccessRights: e.target.dcAccessRights.value,
          dcIsPartOf: e.target.dcIsPartOf.value
        }
        : {
          id: data.id,
          dcTitle: e.target.dcTitle.value,
          dcDescription: e.target.dcDescription.value
        };

    API.patch("archie_api", "/docs", { body: [doc] }).then(val => {
      if (val.statusCode === 200) {
        setData(
          isAdmin
            ? {
              ...data,
              dcTitle: doc.dcTitle,
              dcDescription: doc.dcDescription,
              dcDate: doc.dcDate,
              dcCreator: doc.dcCreator,
              dcSubject: doc.dcSubject,
              storageLocation: doc.storageLocation,
              dcType: doc.dcType,
              dcAccessRights: doc.dcAccessRights,
              dcIsPartOf: doc.dcIsPartOf
            }
            : {
              ...data,
              dcTitle: doc.dcTitle,
              dcDescription: doc.dcDescription
            }
        );
      }
    });

    setEditInfo(false);
  };

  const [text, setText] = useState(data.dcDescription);

  const handleInput = event => {
    setText(event.target.value);
  };
  const numRows = text ? (text.match(/\n/g) || []).length + 1 : 1;
  return (
    <>
      {loading ? <Overlay>מחיקה מתבצעת...</Overlay> : (

        <FormContainer onSubmit={updateDoc} onReset={() => setEditInfo(false)}>
          <Header>
            <Label for="id" >מזהה:</Label>
            <Input name="id" type="text" defaultValue={data.id} disabled />
            <Label for="dcTitle">כותרת:</Label>
            <Input name="dcTitle" type="text" defaultValue={data.dcTitle} />
            <Label for="dcDescription">תיאור:</Label>
            <TextArea
              name="dcDescription"
              type="text"
              value={text}
              onInput={handleInput}
              rows={numRows}
              style={{ minHeight: "74px" }}
            />
            {isAdmin ? (
              <AdminOpts>
                <Label for="dcDate">תאריך: </Label>
                <Input
                  name="dcDate"
                  type="date"
                  defaultValue={
                    data.dcDate
                      ? isNaN(new Date(data.dcDate)) ? null : new Date(data.dcDate).toISOString().split("T")[0]
                      : null
                  }
                />
                <Label for="dcCreator">יוצרים:</Label>
                <Input name="dcCreator" type="text" defaultValue={data.dcCreator} />
                <Label for="dcSubject">נושאים:</Label>
                <Input name="dcSubject" type="text" defaultValue={data.dcSubject} />
                <Label for="storageLocation">מיקום:</Label>
                <Input
                  name="storageLocation"
                  type="text"
                  defaultValue={data.storageLocation}
                />
                <Label for="dcType">סוג:</Label>
                <Select id="dcType" name="סוג" defaultValue={data.dcType}>
                  <option value="image">תמונה</option>
                  <option value="text">מסמך</option>
                  <option value="video">סרטון</option>
                  <option value="audio">קובץ קול</option>
                </Select>
                <Label for="dcAccessRights">סיווג:</Label>
                <Select
                  id="dcAccessRights"
                  name="סיווג"
                  defaultValue={data.dcAccessRights}
                >
                  <option value="public">ציבורי</option>
                  <option value="private">פרטי</option>
                  <option value="secret">סודי</option>
                </Select>
                <Label for="dcIsPartOf">אוסף:</Label>
                <Input
                  name="dcIsPartOf"
                  type="text"
                  defaultValue={data.dcIsPartOf}
                />
              </AdminOpts>
            ) : null}
            <Buttons>
              <Submit type="submit" value="שמירה" />
              <Cancel type="reset" value="ביטול" />
              {isAdmin ? (
                <Delete onClick={deleteDoc}>מחיקה</Delete>
              ) : null}
            </Buttons>
          </Header>
        </FormContainer>)}
    </>
  );
};

const Overlay = styled.div`
width: 100%; 
height:100%; 
color: rgba(200,0,0,0.8);
display: flex;
align-items: center;
justify-content: center;
font-size: 24px;
font: Rubik;
font-weight: bold;
direction: rtl;

`
const FormContainer = styled.form`
  direction: rtl;
  width: 100%;
  height: 100%
  margin-right: 24px;
  padding-left: 48px;
  margin-bottom: 150px;

  @media (min-width: 800px) {
    margin-bottom: 24px;
  }
`;

const Label = styled.label`
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: right;
`;

const Input = styled.input`
  background: #f5f7fa;
  border: 1px solid #dbdbdb;
  border-radius: 1px;
  width: 100%;
  margin: 10px 0px 10px 0px;
  height: 38px;

  :disabled {
      color: black;
  }
`;

const Select = styled.select`
  background: #f5f7fa;
  border: 1px solid #dbdbdb;
  border-radius: 1px;
  width: 100%;
  margin: 10px 0px 10px 0px;
  height: 38px;
`;

const TextArea = styled.textarea`
  background: #f5f7fa;
  border: 1px solid #dbdbdb;
  border-radius: 1px;
  width: 100%;
  margin: 10px 0px 10px 0px;
  resize: none;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 48px 24px 0px 0px;
`;

const AdminOpts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Buttons = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;

`;
const Submit = styled.input`
order:1;
width: 71px;
height: 36px;
background-color: #025ED0;
font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 16px;
border-radius: 4px;
border: none;
text-align: center;
text-transform: uppercase;
margin-left: 16px;
color: #FFFFFF;
cursor: pointer;

}`;

const Cancel = styled.input`
order: 1;
width: 71px;
height: 36px;
background-color: #025ED0;
font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 16px;
border-radius: 4px;
border: none;
text-align: center;
text-transform: uppercase;
margin-left: 16px;

color: #FFFFFF;
cursor: pointer;

}`;

const Delete = styled.button`
order: 3;
margin-left: 0;
margin-right: auto;
width: 71px;
height: 36px;
background-color: red;
font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 16px;
border-radius: 4px;
border: none;
text-align: center;
text-transform: uppercase;
color: #FFFFFF;
cursor: pointer;

}`;
