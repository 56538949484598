class SolrParams {

    // mandatory variables
    q = "*";
    // optional variables
    fq = [];
    sort = undefined
    start = undefined // default = 0
    rows = undefined // default = 10
    hl = undefined // default = on
    hl_fl = undefined // default = content
    facet = undefined
    facet_field = undefined
    facet_sort = undefined
    facet_limit = undefined

    /*
    constants configured by the server (client can't overide)
    fl = "id,dcTitle,dcTitleString,dcDate,dcCreator,dcDescription,dcSubject,storageLocation,dcFormat,dcType,dcIsPartOf,dcAccessRights,importTime"
    wt = "json"
    indent = "on"
    q.op = "AND"
    */

    toQuerySring() {
        // q
        let s = "q=" + this.q;
        // fq
        if (this.fq.length > 0) {
            s = s + "&fq=(" + this.fq.join(" AND ") + ")";
        }
        // sort
        if (this.sort) {
            s = s + "&sort=" + this.sort
        }
        // start
        if (this.start) {
            s = s + "&start=" + this.start
        }
        // rows
        if (this.rows) {
            s = s + "&rows=" + this.rows
        }
        // hl
        if (this.hl) {
            s = s + "&hl=" + this.hl
        }
        // hl.fl
        if (this.hl_fl) {
            s = s + "&hl.fl=" + this.hl_fl
        }
        // facet
        if (this.facet) {
            s = s + "&facet=" + this.facet
        }
        // facet.field
        if (this.facet_field) {
            s = s + "&facet.field=" + this.facet_field
        }
        // facet.sort
        if (this.facet_sort) {
            s = s + "&facet.sort=" + this.facet_sort
        }
        // facet.limit
        if (this.facet_limit) {
            s = s + "&facet.limit=" + this.facet_limit
        }
        // return query string
        return s;
    }
}

export default SolrParams;

// isNaN()