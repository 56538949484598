import styled from "styled-components";
import { Logo } from "../svgs/Logo";
import { topBarLinks } from "../App";
import { TopBar } from "./TopBar";
import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
import React from "react";
import { HamburgerStore } from "./Store";
import { NavLinks } from "./NavLinks";
import { Footer } from "./Footer";
import { useNavigate } from "react-router-dom";

export const BlockPage = () => {
  const { hamburger } = HamburgerStore();

  return (
    <Container>
      {hamburger ? (
        <NavLinks topBarLinks={topBarLinks} />
      ) : (
        <>
          <Container>
            <HeaderContainer>
              <TopBar topBarLinks={topBarLinks} />
              <LogoContainer>
                <Logo width={120} height={139} />
              </LogoContainer>
              <Title>ארכיון בארי</Title>
            </HeaderContainer>
            <Center />
          </Container>
          <Footer />
        </>
      )}
    </Container>
  );
};

export const Center = () => {
  let navigate = useNavigate();

  return (
    <CenterContainer>
      <BlockContainer>
        <StyledH2>ברוכים הבאים לארכיון הדיגיטלי של קיבוץ בארי</StyledH2>
        <StyledH3>
          באתר ניתן למצוא את הגרסה הדיגיטלית של פריטים מתוך הארכיון: מסמכים,
          תמונות, סרטי וידאו, הקלטות, גליונות עיתונות מקומית וקטעי עיתונות
          ארצית, וכמובן - 70 שנות "יומון בארי".
        </StyledH3>
        <WifiLogo>
          <StyledWifi />
        </WifiLogo>
        <StyledH3>
          הפריטים ניתנים לצפיה רק מתוך רשת התקשורת המקומית בקיבוץ בארי. לצפייה
          מהנייד - יש להתחבר באמצעות רשת WiFi ברחבי הקיבוץ (בבית, במקום העבודה,
          בחדר האכילה וכו׳)
        </StyledH3>

        <KedmaButton
          onClick={() => {
            navigate("/kedma-welcome", { replace: false });
          }}
        >
          לארכיון קדמה
        </KedmaButton>
      </BlockContainer>

      <CenterImage src="./assets/beeri-panorama.jpeg" />
    </CenterContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledWifi = styled(WifiRoundedIcon)`
  background-color: #025ed0;
  border-radius: 50%;
  color: white;
`;

const WifiLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  background-color: #025ed0;
  border-radius: 50%;
  color: white;
`;
const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 468px;
`;

const KedmaButton = styled.button`
  width: 184px;
  height: 56px;
  background-color: #025ed0;
  color: white;
  border: none;
  cursor: pointer;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  text-align: center;

  color: #ffffff;
  margin-bottom: 35px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #e7f6ff;
`;

const LogoContainer = styled.div`
  margin-top: 32px;
`;

const Title = styled.h1`
  font-family: Rubik;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin-top: 10px;
  margin-bottom: 8px;
  @media (min-width: 500px) {
    margin-bottom: 24px;
  }
`;

const StyledH2 = styled.h2`
  font-family: Rubik;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #353535;
  margin-top: 34px;
  width: 100%;
`;
const StyledH3 = styled.h3`
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #353535;
  margin-top: 16px;
  margin-bottom: 32px;
`;

const CenterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CenterImage = styled.img`
  object-fit: cover;
  height: 345px;
  width: 100%;
`;
