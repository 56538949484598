import React, { useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Item } from "./Item";
import { PhotoGallery } from "./PhotoGallery";
import { ItemsStore, ViewStore, ScrollStore, FacetsStore } from "./Store";
import { Viewer } from "../components/Viewer";
import { useEffect } from "react";

import { GetSearchResults, BuildSolrSearchQuery } from "./SearchEngine";
export const ItemsCollection = () => {
  const { items } = ItemsStore();
  const { view, setView } = ViewStore();
  const { facets } = FacetsStore();

  const [params, setSearchParams] = useSearchParams();
  const { scrollPosition, setScrollPosition } = ScrollStore();
  const [localScroll, setLocalScroll] = useState(0);

  useEffect(() => {
    if (scrollPosition) {
      window.scrollTo(0, localScroll);
      setScrollPosition(false);
    }
  }, [scrollPosition, localScroll, setScrollPosition]);

  useEffect(() => {
    const onScroll = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setLocalScroll(window.pageYOffset);
      }
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (items.length > 0 && params.has("id")) {
      const item = items.find((item) => item.id === params.get("id"));
      setView(item);
    }
  }, [items, setView, params]);

  const loadMoreItems = () => {
    if (facets.total - parseInt(params.get("firstRow")) >= 50) {
      params.set("firstRow", parseInt(params.get("firstRow")) + 50);

      setSearchParams(createSearchParams(params));
      let searchQuery = "";

      searchQuery = BuildSolrSearchQuery(params);

      GetSearchResults(searchQuery, params, false);
    }
  };

  return (
    <>
      {params.get("dcTypeFilter") !== "image" ? (
        <Container>
          {view ? (
            <Viewer />
          ) : items !== undefined ? (
            <ItemsWrapper>
              <Items>
                {items.map((item) => {
                  return <Item data={item} key={item.id} />;
                })}
              </Items>
              <LoadMoreItemsBtn onClick={loadMoreItems}>
                <LoadMoreItemBtnLabel>טעינת עוד פריטים</LoadMoreItemBtnLabel>
              </LoadMoreItemsBtn>
            </ItemsWrapper>
          ) : null}
        </Container>
      ) : items !== undefined && items.length > 0 ? (
        <PhotoGallery />
      ) : null}
    </>
  );
};

const ItemsWrapper = styled.div`
  width: 100%;
  margin: 16px 24px 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #c6e5f7;
  padding-bottom: 118px;
`;

const Items = styled.div`
  width: 100%;

  display: grid;
  direction: rtl;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-left: -18px;

  @media (min-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(347px, 1fr));
  }

  grid-gap: 24px;
`;

const LoadMoreItemsBtn = styled.button`
  width: 220px;
  height: 56px;
  border-radius: 2px;
  background-color: #296de5;
  margin: 30px 104px 32px 117px;
  padding: 17px 35.5px 15px 36.5px;
  border: none;
  cursor: pointer;
`;

const LoadMoreItemBtnLabel = styled.div`
  width: 148px;

  height: 24px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
`;
