import React from "react";
import styled from "styled-components";

export const Logo = ({ width, height }) => {
  return (
    <Container>
      <svg
        width={width}
        height={height}
        viewBox="0 0 120 139"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#83C7EE"
            d="M2.54 36.128v14.657h114.84V36.128L59.96 2.86z"
          />
          <path
            fill="#F4E7CA"
            d="M2.54 102.789V88.132h114.84v14.657l-57.42 33.267z"
          />
          <path fill="#C3D962" d="M0 91.933h119.742V47.768H0z" />
          <path
            d="M59.96 0 .07 34.697v69.394l59.89 34.697 59.888-34.697V34.697L59.96 0zm1.234 6.437 53.716 31.121v8.97H61.194V6.438zM5.009 37.558 58.725 6.437v40.092H5.009v-8.97zm56.185 52.318h53.716v-40.87H61.194v40.87zm-56.185 0h53.716v-40.87H5.009v40.87zm56.185 2.478h53.716v8.876l-53.716 31.121V92.354zM5.009 101.23v-8.876h53.716v39.997l-53.716-31.12z"
            fill="#353535"
          />
        </g>
      </svg>
    </Container>
  );
};

const Container = styled.div``;
