import React from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";
import { Logo } from "../svgs/Logo";
import { ReactComponent as Hamburger } from "../pictures/hamburger.svg";
import { HamburgerStore } from "./Store";
import { useAuthenticator } from "@aws-amplify/ui-react";
import LogoutIcon from "@mui/icons-material/Logout";
export const TopBar = ({ topBarLinks, isKedma = false }) => {
  const { setHamburger } = HamburgerStore();
  const { user, signOut } = useAuthenticator(context => [context.user]);

  const showUser = () => {
    if (user) {
      return (
        <TopBarOption
          href=""
          title={user.username}
          icon={<StyledLogoutIcon onClick={signOut} />}
        />
      );
    }
  };

  return (
    <Container>
      <LogoContainer>
        <Logo width={32} height={37}></Logo>
      </LogoContainer>

      {isKedma ? (
        <TopBarTitle to="/kedma-welcome">ארכיון קדמה</TopBarTitle>
      ) : (
        <TopBarTitle to="/welcome">ארכיון בארי</TopBarTitle>
      )}
      <HamburgerContainer>
        <Hamburger
          onClick={() => {
            setHamburger(true);
          }}
        ></Hamburger>
      </HamburgerContainer>
      {topBarLinks.map((item, id) => {
        return (
          <TopBarOption
            title={item.title}
            key={id}
            icon={item.icon ? item.icon : null}
            href={item.href}
          />
        );
      })}
      {showUser()}
    </Container>
  );
};

export const TopBarOption = ({ title, icon, href }) => {
  return (
    <OptionContainer
      href={href}
      target={href === "/kedma" ? "_self" : "_blank"}
    >
      {icon}
      {title}
    </OptionContainer>
  );
};

const Container = styled.div`
  height: 56px;
  width: 100vw;
  display: flex;
  color: white;
  justify-content: flex-start;

  @media (min-width: 800px) {
    align-items: center;
    justify-content: space-evenly;

    border-bottom: solid 1px #d8d8d8;
  }
`;

const StyledLogoutIcon = styled(LogoutIcon)`
  width: 16px;
  height: 16px;
  margin: 0px 0px 0px 8px;
`;

const HamburgerContainer = styled.div`
  width: 32px;
  height: 32px;
  margin: 12px;
  cursor: pointer;
  @media (min-width: 800px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
`;

const OptionContainer = styled.a`
  display: none;
  @media (min-width: 800px) {
    display: block;
    padding-left: 24px;
    height: 24px;
    width: auto;
    font-family: Rubik;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: right;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    align-items: center;
    text-decoration: none !important;
  }
`;

const TopBarTitle = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 24px;
  width: 138px;
  font-family: Rubik;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  white-space: nowrap;
  text-decoration: none !important;
`;
