import styled from "styled-components";

import React, { useState, useEffect } from "react";

import { TopBar } from "./TopBar";

import { HamburgerStore } from "./Store";
import { NavLinks } from "./NavLinks";
import { Footer } from "./Footer";

import { topBarLinks } from "../App";

import { Pagination, TextField, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  direction: 'rtl', // Ensure left-to-right direction
});

const PaginationComponent = ({ totalItems, itemsPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState('');
  console.log('test');

  useEffect(() => {
    setInputPage(currentPage.toString());
  }, [currentPage]);

  const handleChange = (event, value) => {
    setCurrentPage(value);
    // Notify the parent component about the selected page
    onPageChange(value);
  };

  const handleInputChange = (event) => {
    const newInputPage = event.target.value;

    // Limit input to the range 1 to 30000
    const pageNumber = Math.min(Math.max(parseInt(newInputPage, 10), 1), 30000);

    setInputPage(pageNumber.toString());
    setCurrentPage(pageNumber);

    // Notify the parent component about the selected page
    onPageChange(pageNumber);
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Pagination
          count={Math.ceil(totalItems / itemsPerPage)}
          page={currentPage}
          onChange={handleChange}
          color="primary"
          showFirstButton
          showLastButton
          style={{ marginBottom: '10px' }}
        />
        <TextField
          label="Go to Page"
          variant="outlined"
          type="number"
          InputProps={{ inputProps: { min: 1, max: Math.ceil(totalItems / itemsPerPage) } }}
          value={inputPage}
          onChange={handleInputChange}
          style={{ width: '100%', maxWidth: '200px' }}
        />
      </div>
    </ThemeProvider>
  );
};


export const Yomons = () => {
  const { hamburger } = HamburgerStore();
  const [externalHtmls, setExternalHtmls] = useState();
  const prefix = "‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏‏"
  const yomons = [
    { num: 19678, name: prefix + "YomonBeeri.2023-6-8.19678" },
    { num: 19764, name: prefix + "YomonBeeri.2023-9-18.19764" },
    { num: 19733, name: prefix + "YomonBeeri.2023-8-11.19733 שישי" },
    { num: 19774, name: prefix + "YomonBeeri.2023-10-1.19774" },
    { num: 19705, name: prefix + "YomonBeeri.2023-7-10.19705" },
    { num: 19779, name: "new_test", encoding: "utf-8" }
  ];
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (selectedPage) => {
    setExternalHtmls()
    const result = yomons.find(({ num }) => num === selectedPage);

    fetchExternalHtml(result?.name, result?.encoding)

    setCurrentPage(selectedPage);
  };

  const fetchExternalHtml = async (name, encoding = "windows-1255") => {

    try {

      const response = await fetch(`https://storage.googleapis.com/yomon_test/${name}.htm`);
      if (response.ok) {
        const contentType = response.headers.get('content-type');

        const arrayBuffer = await response.arrayBuffer();

        // Detect charset from Content-Type header or use a default (e.g., Windows-1255)
        const charset = contentType.match(/charset=(.*)/i)?.[1] || encoding;

        // Convert to UTF-8
        const text = new TextDecoder(charset).decode(arrayBuffer);

        var parser = new DOMParser();
        var doc = parser.parseFromString(text, 'text/html');
        var images = doc.getElementsByTagName('img');
        const domain = `https://storage.googleapis.com/yomon_test/`;
        var tables = doc.getElementsByTagName('table');


        for (var i = 0; i < images.length; i++) {
          var src = images[i].getAttribute('src');
          images[i].setAttribute('src', domain + src);
        }

        for (var j = 0; j < tables.length; j++) {
          var wrapper = document.createElement('div');
          wrapper.style.overflowX = 'auto';
          var existingElement = tables[j];
          existingElement.parentNode.insertBefore(wrapper, existingElement);
          wrapper.appendChild(existingElement);
        }


        var newHtmlText = doc.documentElement.outerHTML;

        setExternalHtmls(newHtmlText)

      }
    } catch (error) {
      console.error('Error fetching external HTML:', error);
    }
  };


  const displayYomons = (currentPage) => {

    return externalHtmls ?

      <External dangerouslySetInnerHTML={{ __html: externalHtmls }} />


      : <h1> לא נמצא </h1>
  }


  return (
    <Container>
      {hamburger ? (
        <NavLinks topBarLinks={topBarLinks} />
      ) : (
        <div>
          <Header />
          <Stam>
            {displayYomons(currentPage)}
          </Stam>
          <PaginationComponent totalItems={30000} itemsPerPage={1} onPageChange={handlePageChange} />
          <Footer />
        </div>
      )}
    </Container>
  );
};

export const Header = () => {
  return (
    <HeaderContainer>
      <TopBar topBarLinks={topBarLinks} />
    </HeaderContainer>
  );
};


export const Center = () => {
  return (
    <CenterContainer>

    </CenterContainer>
  );
};

const External = styled.div`
display:flex;
  flex-direction: column;
  align-items: center;
  word-wrap: break-word;
  
`;

const Stam = styled.div`
display:flex;
align-items: center;
flex-direction: column;
padding: 0 15px 0 15px;
word-wrap: break-word;
p, table, div {
  width: 100% !important;
}

img, span {
  position: relative !important;
  max-width: 100% !important;
  height: auto !important;
}

@media (min-width: 500px) {
  padding: 0 100px 0 100px;
}

`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'David', sans-serif;

`;

const HeaderContainer = styled.div`
  width: 100%;
  background-color: #e7f6ff;
`;

const CenterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


