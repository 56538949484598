import React, { useState } from "react";
import styled from "styled-components";
import { createSearchParams, useSearchParams } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { FacetsStore } from "./Store";
import { GetSearchResults, BuildSolrSearchQuery } from "./SearchEngine";

export const Pagination = () => {
  const [params, setSearchParams] = useSearchParams();

  const [option, setOption] = useState(null);
  const { facets } = FacetsStore();

  let total = facets.total;
  if (params.get("dcTypeFilter") !== "" && facets.counters) {
    total =
      facets.counters.dcType[
        facets.counters.dcType.indexOf(params.get("dcTypeFilter")) + 1
      ];
  }

  if (option != null) {
    let searchQuery = "";
    if (option.custom) {
      params.set("firstRow", 1);
    }

    if (
      parseInt(params.get("firstRow")) + option.val * 50 >= 1 &&
      parseInt(params.get("firstRow")) + option.val * 50 <= total
    ) {
      params.set(
        "firstRow",
        parseInt(params.get("firstRow")) + option.val * 50
      );
    }

    setSearchParams(createSearchParams(params));

    searchQuery = BuildSolrSearchQuery(params);

    GetSearchResults(searchQuery, params, false);
    setOption(null);
  }
  const decPage = () => {
    setOption({ val: -1, custom: false });
  };

  const incPage = () => {
    setOption({ val: 1, custom: false });
  };

  const customPage = (e) => {
    if (e.target.value < 1) {
      e.target.value = 1;
    } else if (e.target.value > Math.ceil(total / 50)) {
      e.target.value = Math.ceil(total / 50);
    }

    const page = parseInt(e.target.value) - 1;

    setOption({ val: page, custom: true });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      console.log("do validate");
      event.preventDefault();
    }
  };

  return total > 50 ? (
    <Container>
      <InnerContainer>
        <div>עמוד </div>
        <RightArrow onClick={decPage} />
        <form>
          <StyledInput
            value={Math.floor(parseInt(params.get("firstRow")) / 50) + 1}
            onChange={customPage}
            onKeyDown={handleKeyDown}
          />
        </form>
        <LeftArrow onClick={incPage} />

        <div>מתוך {Math.ceil(total / 50)}</div>
      </InnerContainer>
    </Container>
  ) : null;
};

const StyledInput = styled.input`
  width: 50px;
  height: 40px;
  margin: 0 4px;
  border-radius: 4px;
  border: solid 1px #9b9b9b;
  background-color: #fff;

  font-family: Rubik;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
`;

const LeftArrow = styled(KeyboardArrowLeftIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
const RightArrow = styled(KeyboardArrowRightIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Container = styled.div`
  display: none;
  display: flex;
  width: 100%;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
  @media (min-width: 500px) {
    display: none;
  }
`;

const InnerContainer = styled.div`
  height: 24px;
  font-family: Rubik;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (min-width: 500px) {
  }
`;
