import styled from "styled-components";

import React from "react";

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent> © ארכיון בארי 2022</FooterContent>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  height: 44px;
  width: 100%;
  background-color: #353535;
  display: flex;
  align-items: center;
  align-self: flex-end;
`;

const FooterContent = styled.div`
  height: 24px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  padding-right: 24px;
`;
