import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  createSearchParams,
  useSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";

import { AdvancedSearch } from "./AdvancedSearch";
import { BuildSolrSearchQuery, GetSearchResults } from "./SearchEngine";
import { ItemsStore } from "./Store";
export const SearchBar = ({ isKedma = false }) => {
  const [runSearch, setRunSearch] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [input, setInput] = useState("");
  let location = useLocation();
  let navigate = useNavigate();
  const { setItems } = ItemsStore();

  const [params, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (params.get("searchTerm")) {
      setInput(params.get("searchTerm"));
    }
    // eslint-disable-next-line
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setRunSearch(true);
    }
  };

  if (runSearch) {
    setItems([])
    let searchQuery = "";

    const current = Object.fromEntries([...params]);
    if (current["searchTermModifier"] === undefined) {
      params.set("searchTermModifier", "any");
    }

    if (isKedma) {
      params.set("dcCollectionFilter", "קיבוץ קדמה"); //TODO: check if it doesn't create unneeded extra calls
    }


    params.set("firstRow", 1);
    params.set("numberOfRows", 50);

    params.set("dcSubject", "");

    if (current["sortField"] === undefined) {
      params.set("sortField", "dcDate");
    }

    if (current["sortOrder"] === undefined) {
      params.set("sortOrder", "desc");
    }

    if (current["sortOrder"] === undefined) {
      params.set("dcTypeFilter", "");
    }


    params.set("searchTerm", input ? input : "*");

    setSearchParams(createSearchParams(params));

    searchQuery = BuildSolrSearchQuery(params);

    if (location.pathname === "/kedma-welcome") {
      navigate("/kedma?" + params.toString(), { replace: false });
    }

    else if (location.pathname === "/welcome") {
      navigate("/search?" + params.toString(), { replace: false });
    }

    else {

      if (current["searchTerm"] === input) {
        GetSearchResults(searchQuery, params, false);
      }

      setRunSearch(false);
    }
  }
  return (
    <>
      <Container isWelcome={location.pathname === "/welcome"}>
        <Search>
          <SearchLupa onClick={() => setRunSearch(true)} />
          <SearchField
            value={input}
            onInput={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="חיפוש בארכיון"
          />
          <SearchArrow onClick={() => setAdvancedSearch(!advancedSearch)} />
        </Search>
        {advancedSearch ? <AdvancedSearch searchTerm={input} /> : null}
      </Container>
    </>
  );
};

const Container = styled.div`
  height: 64px;
  width: 100vw;
  display: flex;
  justify-content: ${(props) => (props.isWelcome ? "center" : "start")};
  flex-wrap: wrap;
  max-width: 500px;
`;

const Search = styled.div`
  height: 48px;
  border-radius: 4px;
  margin: 16px 16px 2px 16px;

  background-color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
`;

const SearchArrow = styled(ArrowDropDownIcon)`
  width: 24px;
  height: 24px;
  margin-left: 12px;
  cursor: pointer;
`;

const SearchLupa = styled(SearchIcon)`
  width: 17.5px;
  height: 17.5px;
  color: #53555a;
  margin-right: 15.5px;
  cursor: pointer;
`;

const SearchField = styled.input`
  max-width: 400px;
  width: 100%;
  height: 24px;
  margin: 0 12px 0 0;
  font-family: Rubik;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
  outline: none;
  border: none;
  background-color: transparent;
  direction: rtl;

  @media (min-width: 800px) {
  }
`;
