import { Auth } from "aws-amplify";

const awsExports = {
  Auth: {
    region: "eu-west-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "archie_api",
        endpoint: "https://" + process.env.REACT_APP_API_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`
          };
        }
      }
    ]
  }
};

export default awsExports;
